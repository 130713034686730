<template>
  <div>
    <van-cell title="请选择你要查询的日期" :value="date" @click="show = true" />
    <van-calendar v-model="show" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
    <!-- 内容部分 -->
    <van-collapse v-model="activeNames" v-for="item in recordFrom" :key="item.id">
      <van-collapse-item :name="item.id" :label="item.content">
        <template #title>
          <div style="display:flex;justify-content: space-between;">
            <div>{{item.create_time}}</div>
            <div>{{item.animal_operate_name}}</div>
          </div>
        </template>
          <div class="imgDiv" v-for="item in item.fileAttachList" :key="item.id">
            <img class="image" :src="item.full_path" alt="">
          </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { animalList, animalListAll } from "../../service/livestock/livestock"
import { Cell, Calendar, Collapse, CollapseItem } from "vant"
export default {
    props: ["id"],
  data() {
    return {
        show: false,
        date: "",
        minDate: new Date(2010, 0, 1),
        maxDate: new Date(),
        current: 1,
        size: 30,
        recordFrom: [],
        activeNames: ['1']
    }
  },
  components: {
    "van-cell": Cell,
    "van-calendar": Calendar,
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    const date = new Date()
    this.date = this.formatDate(date);
    this.getanimalListAll()
  },
  methods: {
    async getanimalListAll() {
      const res = await animalListAll(this.current, this.size, this.id)
      this.recordFrom = res.data.records
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      animalList(this.id, this.date).then((res) => {
        this.recordFrom = res.data
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.imgDiv{
  display: flex;
  flex-wrap: wrap;
  .image{
    width: 25vw;
    height: 14vh;
  }
}
</style>
