// 牲畜详情
import server from "../axios.config.js";

// 根据id查询
export const animalById = function(id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/animal/getById?id=${id}`,
        method: "GET"
    })
}

// 获取养殖操作
export const animalList = function(id, date) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/animalOperateRec/selectList?animal_id=${id}&oper_date=${date}`,
        method: "GET"
    })
}

// 获取所有的养殖操作
export const animalListAll = function(current, size, id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/animalOperateRec/selectPageListAll?current=${current}&size=${size}&animal_id=${id}`,
        method: "GET"
    })
}

// 根据id获取当日操作记录
export const animalselectToday = function (id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/animalOperateRec/selectTodayList?animal_id=${id}`,
        method: "GET"
    })
}

// 新增养殖操作记录
export const animalSave = function(param) {
    return server({
        url: "http://110.87.103.59:18086/farmfriend/animalOperateRec/save",
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: param
    })
}

// 牲畜出栏

export const animalPen = function(data) {
    return server({
        url: "http://110.87.103.59:18086/farmfriend/animal/outPen",
        method: "POST",
        data: data
    })
}